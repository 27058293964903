import Clipboard from 'clipboard';

export const addScrollToH2 = () => {
  if (window.location.href.includes('#')) {
    const id = window.location.href.split('#')[1];
    const element = document.querySelector(`a[href='#${id}']`);

    // doesnt work without setTimeout (content needs a bit to render and messes up scrollTop position of element)
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }, 200);
  }
};

function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

export const addLinkToH2 = (query = 'article .content h2') => {
  const h2s = document.querySelectorAll(query);

  for (let i = 0; i < h2s.length; i++) {
    const h2 = h2s[i];
    const sanitizedText = convertToSlug(h2.textContent);

    const parent = h2.parentNode;

    const wrapper = document.createElement('div');
    wrapper.classList.add('h2-scroll');

    const link = document.createElement('a');
    link.classList.add('h2-scroll-link');
    link.href = '#' + sanitizedText;

    const icon = document.createElement('span');
    icon.classList.add('icon', 'icon-hlevel-link');

    link.appendChild(icon);

    parent.replaceChild(wrapper, h2);
    wrapper.appendChild(h2);
    wrapper.appendChild(link);
  }
};

export const addCopyToH2 = (
  query = 'article .content .guides-section-link',
) => {
  new Clipboard(query, {
    text: function(trigger) {
      return trigger.href;
    },
  });
};
