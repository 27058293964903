import React, { useEffect } from 'react';

import Layout from '../layouts/Layout';
import { Row, Col, Container, Card } from 'react-bootstrap';
import Author from '../components/Author';
import Toc from '../components/Toc';
import SocialShare from '../components/SocialShare';
import RelatedPosts from '../components/RelatedPosts';
import { graphql, Link } from 'gatsby';
import CenteredImage from '../components/CenteredImage';
import FooterSection from '../components/FooterSection';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import { addScrollToH2, addCopyToH2, addLinkToH2 } from '../helper/headerFeatures';

/**
 * Post Template used for all posts.
 * @param pageContext
 * @param data
 */
const Post = ({ pageContext, data, location }) => {
  const post = data.post;
  const authorImage =
    post?.post_author?.acf?.image?.localFile?.childImageSharp?.fluid;
  const relatedPosts = post.acf.related_posts;
  const stringTranslations = pageContext.stringTranslations;

  const { pathname } = useLocation();

  const authorName =
    post.post_author.wordpress_id === 12
      ? stringTranslations['The Amazon Associates Team']
      : post.post_author.name;

  useEffect(() => {
    addLinkToH2();
    addScrollToH2();
    addCopyToH2('article .content .h2-scroll-link');
  }, []);

  return (
    <Layout
      context={pageContext}
      className="post-page"
      useContainer={false}
      location={location}
      progressBarTarget="article"
    >
      <Helmet>
        <link
          rel="amphtml"
          href={data.site.siteMetadata.siteUrl + pathname + 'amp/'}
        />
      </Helmet>
      <article className="article">
        <Container>
          <header>
            <Row className="justify-content-center">
              <Col lg={5} className="d-flex flex-column justify-content-end">
                {post.post_categories && post.post_categories.length > 0 && (
                  <p
                    className="roofline"
                    dangerouslySetInnerHTML={{
                      __html: post.post_categories[0].name,
                    }}
                  />
                )}
                <h1 dangerouslySetInnerHTML={{ __html: post.post_title }} />
                {post.acf.excerpt && (
                  <div
                    className="intro"
                    dangerouslySetInnerHTML={{ __html: post.acf.excerpt }}
                  />
                )}
                <Author
                  image={authorImage?.src}
                  imageSrcSet={authorImage?.srcSet}
                  name={authorName}
                >
                  {stringTranslations['Last updated']} {post.post_date} •{' '}
                  {post.time_to_read.minutes} {stringTranslations['Min Read']}
                </Author>
              </Col>
              {post.featured_media && (
                <Col lg={5} className="post-page__feat-image">
                  <CenteredImage
                    alt={post.post_title}
                    source={
                      post.featured_media?.localFile?.childImageSharp?.fluid
                        ?.src
                    }
                    sourceSet={
                      post.featured_media?.localFile?.childImageSharp?.fluid
                        ?.srcSet
                    }
                    caption={post.featured_media?.caption}
                  />
                </Col>
              )}
            </Row>
          </header>

          <Row>
            <Col lg={{ span: 6, offset: 1 }}>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: post.post_content }}
              />
            </Col>
            <Col lg={{ span: 3, offset: 1 }}>
              <Toc enter={150} title={stringTranslations['Contents']} />
            </Col>
          </Row>

          <div className="post-footer">
            <Row>
              <Col lg={{ span: 6, offset: 1 }}>
                <RelatedPosts
                  posts={post.acf.related_posts}
                  title={stringTranslations['Learn More']}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={{ span: 6, offset: 1 }}>
                <SocialShare
                  path="bla"
                  postId={pageContext.id}
                  postTitle={post.post_title}
                />
              </Col>
            </Row>

            {post.post_author && (
              <Row>
                <Col lg={{ span: 6, offset: 1 }}>
                  <Author
                    image={authorImage?.src}
                    imageSrcSet={authorImage?.srcSet}
                    name={authorName}
                    variant="big"
                    by={stringTranslations['by']}
                  >
                    {post?.post_author?.description}
                  </Author>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </article>

      {relatedPosts && relatedPosts.length > 0 && (
        <FooterSection title="Related Articles">
          {relatedPosts.map(post => (
            <Col lg={4} className="d-flex align-items-stretch">
              <Link to={post.path} className="d-flex w-100">
                <Card className="related-articles__card">
                  {post?.image?.url?.localFile?.childImageSharp?.fixed
                    ?.srcSet && (
                    <img
                      alt={post.post_title}
                      src={
                        post?.image?.url?.localFile?.childImageSharp?.fixed?.src
                      }
                      srcSet={
                        post?.image?.url?.localFile?.childImageSharp?.fixed
                          ?.srcSet
                      }
                    />
                  )}
                  <Card.Body>
                    {post?.category?.name && (
                      <Card.Subtitle>{post.category.name}</Card.Subtitle>
                    )}
                    <Card.Title>{post.post_title}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </FooterSection>
      )}
    </Layout>
  );
};

export const query = graphql`
  query PostQuery($id: Int!, $languageCode: String!) {
    site: site {
      siteMetadata {
        siteUrl
      }
    }
    post: wordpressPost(
      wordpress_id: { eq: $id }
      language: { language_code: { eq: $languageCode } }
    ) {
      wordpress_id
      slug
      post_title
      post_content
      acf {
        related_posts {
          post_title
          path
          category {
            name
          }
          image {
            url {
              localFile {
                childImageSharp {
                  fixed(
                    width: 100
                    quality: 100
                    height: 100
                    cropFocus: CENTER
                    fit: COVER
                  ) {
                    srcSet
                    src
                  }
                }
              }
            }
          }
        }
        excerpt
      }
      path
      post_date(locale: $languageCode, formatString: "MMMM D, YYYY")
      post_author {
        description
        name
        wordpress_id
        acf {
          image {
            localFile {
              childImageSharp {
                fluid(jpegQuality: 90, maxWidth: 980) {
                  srcSet
                  src
                }
              }
            }
          }
        }
      }
      time_to_read {
        word_count
        minutes
      }
      featured_media {
        caption
        localFile {
          childImageSharp {
            fluid(jpegQuality: 100, maxWidth: 980) {
              srcSet
              src
            }
          }
        }
      }
      post_categories {
        path
        name
        term_id
      }
      language {
        locale
        language_code
        root_path
        locale_native_name
      }
      translations {
        locale
        locale_native_name
        locale_short
        href
      }
    }
  }
`;

export default Post;
