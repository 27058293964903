import React from "react";
import PropTypes from "prop-types";

/**
 * Displays Post List as Related Links. Sometimes also called learn more.
 * Does not render if posts array does not contain values.
 *
 * @param posts - Post Array containing post title and path
 * @param title - Section Header Title
 */
const RelatedPosts = ({ posts, title }) => {
  return posts ? (
    <div className="related-posts">
      <div className="related-posts__header">
        <h5>{title}</h5>
      </div>
      <ul className="related-posts__list internal-links">
        {posts.map(post => (
          <li key={post.post_title}>
            <a href={post.path}>{post.post_title}</a>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

RelatedPosts.defaultProps = {
  title: "Learn More"
};

RelatedPosts.propTypes = {
  title: PropTypes.string,
  posts: PropTypes.array.isRequired
};

export default RelatedPosts;
